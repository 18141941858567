/* Header.css */

header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16.2px;
  max-width: 100%;
}

.menu-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

h1 {
  margin: 0;
  color: #fff; /* White title color */
}

nav {
  display: flex;
  gap: 16.2px;
}
.menu-toggle {
  display: none;
}

@media (max-width: 972px) {
  header {
    flex-direction: column;
    align-items: flex-start;
    max-width: 100%;
  }

  nav {
    display: none; /* Hide nav by default on smaller screens */
    flex-direction: column;
    width: 100%; /* Full width for mobile view */
    margin-top: 10px;
  }

  .menu-toggle {
    position: relative;
    display: block; /* Show menu toggle button */
    background: none;
    border: none;
    color: #fff;
    font-size: 8.1vh;
    cursor: pointer;
    padding: 16.2px;
  }

  /* Open navigation menu on click */
  nav.open {
    display: flex; /* Show nav when menu is open */
  }

  .border-box {
    margin-bottom: 8.1px;
  }
}
