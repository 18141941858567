* {
  margin: 0;
  background-color: #000;
  color: #fff;
}

.App {
  text-align: center;
  max-width: 100%;
}
body {
  max-width: 100%;
}

h1 {
  font-size: 8.1vh;
}

h2 {
  font-size: 16.2vw;
  max-width: 100%;
}

p {
  font-size: 1.62rem;
}

a {
  text-decoration: none;
  font-size: 1.62rem;
}

.border-box {
  align-content: center;
  position: relative;
  padding: 16.2px;
  margin: 16.2px;
  border-radius: 16.2px;
  background-color: #000; /* Black background */
  color: #fff; /* White text */
  border: 1.62px solid transparent; /* Set the border width */
  animation: gradient-border 1.62s linear infinite;
}

/* Create the gradient border effect */
@keyframes gradient-border {
  0%,
  100% {
    border-image: linear-gradient(
        45deg,
        #ffff00,
        #00ff00,
        #0099ff,
        #001aff,
        #a200ff,
        #ff0055,
        #ff0000,
        #ff0055,
        #ff0000,
        #ffff00,
        #00ff00,
        #0099ff,
        #001aff,
        #a200ff
      )
      1;
  }
  50% {
    border-image: linear-gradient(
        45deg,
        #a200ff,
        #ff0055,
        #ff0000,
        #ffff00,
        #00ff00,
        #0099ff,
        #001aff,
        #0099ff,
        #00ff00,
        #ffff00,
        #ff0000,
        #ff0055,
        #a200ff,
        #001aff
      )
      1;
  }
}
