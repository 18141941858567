.input-container {
  min-width: 50%;
  height: 8.1vh;
}

input {
  min-width: 50%;
  height: 8.1vh;
}

@media (max-width: 1100px) {
  .input-container {
    min-width: 100%;
    height: 8.1vh;
  }

  input {
    min-width: 100%;
    height: 8.1vh;
  }
}
